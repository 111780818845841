import {
	type HeadersFunction,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
	json,
} from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	useLoaderData,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Settings } from "luxon";
import nProgressStyles from "nprogress/nprogress.css?url";
import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "remix-i18next/react";
import { Alert } from "~/components/ui/alert";
import { Toaster } from "~/components/ui/sonner";
import type { ENV } from "~/env";
import { EnvContextProvider } from "~/hooks/use-env-context";
import useNprogress from "~/hooks/use-nprogress";
import { getUser } from "~/lib/auth/session.server";
import config from "~/lib/config.server";
import i18next, { localeCookie } from "~/lib/i18n/i18next.server";
import styles from "~/styles.css?url";

export const handle = {
	i18n: "common",
};

export const meta: MetaFunction = () => [
	{
		title: "Vendorvue Self Service",
	},
];

export const links: LinksFunction = () => [
	{ rel: "stylesheet", href: styles },
	{ rel: "stylesheet", href: nProgressStyles },
];

export const headers: HeadersFunction = () => ({
	"Content-Security-Policy": `
		default-src 'self';
		style-src 'self' https://*.posthog.com 'unsafe-inline';
		script-src 'self' https://unpkg.com https://eu-assets.i.posthog.com https://*.posthog.com 'unsafe-inline';
		img-src 'self' https://logo.clearbit.com https://workoscdn.com https://*.s3.eu-central-1.amazonaws.com https://images.vendorvue.app https://images.vendorvue.dev https://*.vendorvue.workers.dev data:;
		connect-src 'self' https://*.s3.eu-central-1.amazonaws.com https://*.sentry.io https://eu.i.posthog.com https://*.posthog.com https://internal-t.posthog.com;
		worker-src 'self' blob:;
		child-src https://*.intercom.io blob:;`
		.replace(/\s+/g, " ")
		.trim(),
	// anti-clickjacking: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Frame-Options
	"X-Frame-Options": "DENY",
	// force https
	"Strict-Transport-Security": "max-age=31536000; includeSubDomains",
	// Prevent MIME type sniffing
	"X-Content-Type-Options": "nosniff",
	// Control referrer information
	"Referrer-Policy": "strict-origin-when-cross-origin",
	// Prevent caching by default, signal that no intermediaries should cache
	"Cache-Control": "private, no-store",
	Pragma: "no-cache",
});

export async function loader(args: LoaderFunctionArgs) {
	const userData = await getUser(args);
	const locale =
		userData?.user.language ?? (await i18next.getLocale(args.request));
	const ENV: ENV = {
		APP_ENV: config.APP_ENV,
		SENTRY_DSN: config.SENTRY_DSN,
		IMAGES_BASE_URL: config.IMAGES_BASE_URL,
	};
	return json(
		{
			locale,
			ENV,
		},
		{
			headers: {
				"Set-Cookie": await localeCookie.serialize(locale),
			},
		},
	);
}

export default function Route() {
	const loaderData = useLoaderData<typeof loader>();
	const { i18n } = useTranslation();

	useNprogress();

	useChangeLanguage(loaderData?.locale ?? "");
	// Make sure all dates using Luxon (client-side) are using the user's locale.
	Settings.defaultLocale = loaderData?.locale ?? undefined;

	return (
		<html lang={loaderData?.locale} dir={i18n.dir()}>
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<link rel="icon" href="/favicon.png" />
				<Links />
			</head>
			<body className="h-screen w-screen overflow-hidden">
				<div className="w-full h-full overflow-auto">
					<EnvContextProvider env={loaderData.ENV}>
						<Outlet />
					</EnvContextProvider>
				</div>
				<Toaster
					richColors
					position="top-right"
					toastOptions={{ duration: 6000 }}
				/>
				<ScrollRestoration />
				<Scripts />
				<script
					// biome-ignore lint/security/noDangerouslySetInnerHtml: We control the content of this script tag
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(loaderData?.ENV ?? "{}")}`,
					}}
				/>
			</body>
		</html>
	);
}

export function ErrorBoundary() {
	// TODO: do translations work correctly, since we cannot use the loaderData here?
	const error = useRouteError();
	console.error({ error });
	captureRemixErrorBoundaryError(error);
	const message =
		isRouteErrorResponse(error) && error.status === 404
			? "Page not Found"
			: "Something went wrong";
	return (
		// biome-ignore lint/a11y/useHtmlLang: we cannot use the loaderData here
		<html>
			<head>
				<Meta />
				<Links />
			</head>
			<body>
				<div className="p-16">
					<Alert variant="destructive">{message}</Alert>
				</div>
				<Scripts />
			</body>
		</html>
	);
}
