import type { InitOptions } from "i18next";
import zodEn from "zod-i18n-map/locales/en/zod.json";
import zodFr from "zod-i18n-map/locales/fr/zod.json";
import zodNl from "zod-i18n-map/locales/nl/zod.json";
import commonEn from "./locales/en/common.json";
import commonFr from "./locales/fr/common.json";
import commonNl from "./locales/nl/common.json";

export default {
	supportedLngs: ["en", "nl", "fr"] as const,
	fallbackLng: "en",
	defaultNS: "common",
	resources: {
		en: {
			zod: zodEn,
			common: commonEn,
		},
		nl: {
			zod: zodNl,
			common: commonNl,
		},
		fr: {
			zod: zodFr,
			common: commonFr,
		},
	},
} satisfies InitOptions;
