import confetti from "canvas-confetti";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatCurrency(input?: number | null) {
	if (Number.isNaN(input) || input === null || input === undefined) {
		return "–";
	}
	return new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 0,
	}).format(input);
}

export function getInitials(name?: string) {
	const DEFAULT_INITIALS = "VV";
	try {
		if (!name) return DEFAULT_INITIALS;

		const [first, last] = name.split(" ");
		return `${first[0]}${last[0]}`;
	} catch (error) {
		return DEFAULT_INITIALS;
	}
}

export function sprinkleConfetti() {
	confetti({
		particleCount: 150,
		spread: 80,
		origin: { y: 0.6 },
	});
	const duration = 2 * 1000;
	const animationEnd = Date.now() + duration;
	const defaults = { startVelocity: 60, spread: 300, ticks: 60, zIndex: 0 };

	const randomInRange = (min: number, max: number) => {
		return Math.random() * (max - min) + min;
	};

	const interval: NodeJS.Timeout = setInterval(() => {
		const timeLeft = animationEnd - Date.now();

		if (timeLeft <= 0) {
			return clearInterval(interval);
		}

		const particleCount = 50 * (timeLeft / duration);
		// since particles fall down, start a bit higher than random
		confetti({
			...defaults,
			particleCount,
			origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
		});
		confetti({
			...defaults,
			particleCount,
			origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
		});
	}, 250);
}
